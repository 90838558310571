<template>
  <div class="delivery-order-payment-container">
    <my-nav-bar
      title="付款信息"
      left-text="返回"
      right-text="撤销付款"
      left-arrow
      @click-right="cancelPay"
    />
    <div class="app-main-container">
      <div v-if="payInfo" class="delivery-order-payment-content">
        <div class="tips">提示：如果有修改，请重新点击`确认提交`</div>
        <van-cell-group title="支付明细">
          <van-cell title="订单总额" :value="'￥' + payInfo.amount" value-class="cell-amount-value" />
          <van-cell v-if="depositAmount < 0" title="扣减定金" :value="'￥' + depositAmount" value-class="cell-amount-value" />
          <van-cell v-if="payInfo.red_packet_amount < 0" title="扣减红包" :value="'￥' + payInfo.red_packet_amount" value-class="cell-amount-value" />
          <van-cell v-if="payInfo.coupon_amount < 0" title="扣减优惠券" :value="'￥' + payInfo.coupon_amount" value-class="cell-amount-value" />
          <van-cell v-if="payInfo.return_amount < 0" title="扣减退货" :value="'￥' + payInfo.return_amount" center value-class="cell-amount-value" />
          <van-cell v-if="payInfo.unpaid_amount < 0" title="扣减签单" :value="'￥' + payInfo.unpaid_amount" center value-class="cell-amount-value" />
          <van-cell v-if="payInfo.decimal_amount < 0" title="扣减尾数" :value="'￥' + payInfo.decimal_amount" value-class="cell-amount-value" />
          <van-cell title="应收" :value="'￥' + payInfo.real_amount" value-class="cell-amount-value" />
          <van-cell title="退货/签单" is-link center @click="showOperations = true" />
          <van-cell v-if="payInfo.coupon_tips" title="优惠券取消提醒：" :label="payInfo.coupon_tips" />
          <van-cell v-if="payInfo.red_packet_tips" title="红包取消提醒：" :label="payInfo.red_packet_tips" />
        </van-cell-group>

        <van-cell-group v-if="payInfo.unpaid_amount < 0" title="客户签单">
          <van-cell v-if="payInfo.unpaid_return_amount < 0" title="签单退货金额" :value="'￥' + payInfo.unpaid_return_amount" value-class="cell-amount-value" />
          <van-cell v-if="payInfo.unpaid_return_amount < 0" title="签单退货单据" :value="getUnpaidReturnOrderNum(payInfo.unpaid_return_order_id)" value-class="return-receipt" is-link center @click="showUnpaidReturnDialog = true" />
          <van-cell title="本次签单金额" :value="'￥' + payInfo.real_unpaid_amount" value-class="cell-amount-value" />
        </van-cell-group>

        <!-- <div>
          <van-button class="delivery-order-payment-submit-btn" block round type="warning" @click="showOperations = true">退货 | 签单</van-button>
        </div> -->

        <van-cell-group title="支付方式">
          <!-- <van-cell class="delivery-order-payment-input-mode" title="签单收款" center>
            <van-switch v-model="showIou" size="20px" />
          </van-cell> -->
          <van-cell class="delivery-order-payment-input-mode" title="输入模式" center>
            <van-switch v-model="inputMode" size="20px" />
          </van-cell>
          <van-cell title="查看收款码" is-link @click="handleShowPaymentQrcode" />
          <van-radio-group v-model="payType" direction="horizontal">
            <van-cell class="delivery-order-payment-mode" title="线上支付" center @click="payType = '1'">
              <template #icon>
                <span class="delivery-order-payment-mode__icon">
                  <van-icon class="delivery-order-payment-mode__icon__wxpay" class-prefix="mhj-icon" name="weixinzhifu" />
                </span>
              </template>
              <template v-if="!inputMode" #right-icon>
                <van-radio name="1" />
              </template>
              <van-field
                v-else
                v-model="wxpayAmount"
                class="delivery-order-payment-mode__input"
                input-align="right"
                placeholder="支付金额"
                type="number"
              />
            </van-cell>
            <van-cell class="delivery-order-payment-mode" title="现金" center @click="payType = '3'">
              <template #icon>
                <span class="delivery-order-payment-mode__icon">
                  <van-icon class="delivery-order-payment-mode__icon__cash" class-prefix="mhj-icon" name="xianjin" />
                </span>
              </template>
              <template v-if="!inputMode" #right-icon>
                <van-radio name="3" />
              </template>
              <van-field
                v-else
                v-model="cashAmount"
                class="delivery-order-payment-mode__input"
                input-align="right"
                placeholder="支付金额"
                type="number"
              />
            </van-cell>
            <van-cell class="delivery-order-payment-mode" title="转账" center @click="payType = '4'">
              <template #icon>
                <span class="delivery-order-payment-mode__icon">
                  <van-icon class="delivery-order-payment-mode__icon__trans" class-prefix="mhj-icon" name="yinxingqia" />
                </span>
              </template>
              <template v-if="!inputMode" #right-icon>
                <van-radio name="4" />
              </template>
              <van-field
                v-else
                v-model="transAmount"
                class="delivery-order-payment-mode__input"
                input-align="right"
                placeholder="支付金额"
                type="number"
              />
            </van-cell>
          </van-radio-group>
        </van-cell-group>

        <van-button class="delivery-order-payment-submit-btn" block round type="primary" @click="handleSubmit">确认提交</van-button>
        <van-button class="order-next-btn" block round type="danger" @click="handleOrderNext">下次配送</van-button>
      </div>
    </div>

    <van-dialog
      v-model="showDialog"
      title="签单"
      show-cancel-button
      confirm-button-text="确定"
      confirm-button-color="#1989fa"
      @confirm="handleUnpaid"
    >
      <div class="delivery-order-payment-unpaid">
        <van-cell center :border="false" title="输入模式">
          <template #right-icon>
            <van-switch v-model="unpaidInputMode" size="20px" />
          </template>
        </van-cell>
        <van-checkbox-group v-model="unpaidOrderIds" class="unpaid-group">
          <van-cell
            v-for="(order, index) in orders"
            :key="index"
            class="delivery-order-payment-unpaid__cell"
            :title="order.num"
            center
            @click="unpaidInputMode ? () => {} : toggle(index)"
          >
            <template v-if="!unpaidInputMode" #right-icon>
              <van-checkbox ref="checkbox" :name="order.id" />
            </template>
            <van-field
              v-else
              v-model="order.unpaid_amount"
              class="delivery-order-payment-mode__input"
              input-align="right"
              placeholder="签单金额"
              type="number"
            />
          </van-cell>
        </van-checkbox-group>
      </div>
    </van-dialog>

    <van-dialog
      v-model="showUnpaidReturnDialog"
      title="签单退货单据"
      show-cancel-button
      confirm-button-text="确定"
      confirm-button-color="#1989fa"
      @confirm="handleUnpaidReturn"
    >
      <div v-if="payInfo" class="delivery-order-payment-unpaid">
        <div class="uop-tips">提示：请选择写有退货的单据</div>
        <van-radio-group v-model="payInfo.unpaid_return_order_id">
          <van-cell
            v-for="(order, index) in orders"
            :key="index"
            class="delivery-order-payment-unpaid__cell"
            :title="order.num"
            center
            @click="toggle(index)"
          >
            <template #right-icon>
              <van-radio ref="radio" :name="order.id" />
            </template>
          </van-cell>
        </van-radio-group>
      </div>
    </van-dialog>

    <van-dialog
      v-model="showPaymentDialog"
      title="收款信息"
      show-cancel-button
      confirm-button-text="确定"
      confirm-button-color="#1989fa"
      class="my-van-dialog-css"
      @confirm="handlePayment"
    >
      <div v-if="recvInfo" class="payment-info">
        <!-- <van-image width="100%" :src="recvInfo.pay_qrcode" /> -->
        <div class="payment-info-trans">
          <van-cell title="开户行" :value="recvInfo.bank_name" />
          <van-cell title="开户名" :value="recvInfo.bank_account" />
          <van-cell title="银行卡号" :value="recvInfo.bank_card_num" />
        </div>
      </div>
    </van-dialog>

    <my-dialog
      v-model="showPaymentQrcodeDialog"
      title="收款码"
      :show-cancel-button="false"
    >
      <div class="payment-qrcode">
        <van-image :src="qrcodeData" />
      </div>
    </my-dialog>

    <van-action-sheet v-model="showOperations" :actions="actions" @select="selectOperation" />

  </div>
</template>

<script>
import deliveryOrderApi, { setUnpaidReturn, toOrderNext } from '@/api/delivery_order'
import MyNavBar from '@/components/my-nav-bar'
import MyDialog from '@/components/my-dialog'
import qrcode from 'qrcode'
// import CryptoJS from 'crypto-js'
// import wx from 'weixin-js-sdk'
// import { lazyAMapApiLoaderInstance } from 'vue-amap'
// import { Dialog } from 'vant'

export default {
  name: 'DeliveryOrderPayment',
  components: { MyNavBar, MyDialog },
  data() {
    return {
      clientId: this.$route.query.client_id,
      payOrderNum: this.$route.query.pay_order_num,
      payInfo: null,
      inputMode: false,
      unpaidInputMode: false,
      payType: '1',
      wxpayAmount: '',
      cashAmount: '',
      transAmount: '',
      orders: null,
      showDialog: false,
      unpaidOrderIds: [],
      payAmount: 0,
      showIou: false,
      showPaymentDialog: false,
      recvInfo: null,
      detailAddress: '',
      locationMsg: null,
      saveTude: null,
      showUnpaidReturnDialog: false,
      showOperations: false,
      actions: [
        { name: '退货', type: 1 },
        { name: '签单', type: 2 }
      ],
      showPaymentQrcodeDialog: false,
      qrcodeData: null
    }
  },
  computed: {
    depositAmount() {
      return (Number(this.payInfo.deposit_wxpay_amount) +
          Number(this.payInfo.deposit_alipay_amount) +
          Number(this.payInfo.deposit_cash_amount)).toFixed(2)
    }
  },
  watch: {
    inputMode() {
      this.wxpayAmount = ''
      this.cashAmount = ''
      this.transAmount = ''
    }
  },
  created() {
    this.getPayInfo()
    this.getOrders()
    this.getRecvInfo()
  },
  methods: {
    getPayInfo() {
      const params = {
        client_id: this.clientId,
        payment_id: this.$route.query.payment_id,
        bill_reject_id: this.$route.query.bill_reject_id
      }
      deliveryOrderApi.payInfo(params).then(res => {
        this.payInfo = res.data
      })
    },
    getOrders() {
      const params = {
        client_id: this.clientId,
        bill_reject_id: this.$route.query.bill_reject_id
      }
      deliveryOrderApi.order(params).then(res => {
        this.orders = res.data
      })
    },
    getRecvInfo() {
      deliveryOrderApi.recvInfo().then(res => {
        this.recvInfo = res.data
      })
    },
    handleSubmit() {
      if (!this.inputMode) {
        this.wxpayAmount = 0
        this.cashAmount = 0
        this.transAmount = 0
        switch (this.payType) {
          case '1':
            this.wxpayAmount = this.payInfo.real_amount
            break
          case '3':
            this.cashAmount = this.payInfo.real_amount
            break
          case '4':
            this.transAmount = this.payInfo.real_amount
            break
          default:
            break
        }
      }

      this.payAmount = (Number(this.wxpayAmount) +
          Number(this.cashAmount) +
          Number(this.transAmount)).toFixed(2)

      if (this.payAmount !== this.payInfo.real_amount) {
        this.fail('支付金额不等于应付金额')
        return
      }

      if (this.transAmount > 0) {
        this.showPaymentDialog = true
      } else if (this.wxpayAmount > 0) {
        this.handlePayment()
      } else {
        this.handlePayment()
      }
    },
    handlePayment() {
      const data = {
        client_id: this.clientId,
        wxpay: Number(this.wxpayAmount),
        cash: Number(this.cashAmount),
        trans: Number(this.transAmount),
        bill_reject_id: this.$route.query.bill_reject_id
      }
      this.beginLoad()
      deliveryOrderApi.payment(data).then(res => {
        this.endLoad()
        this.$router.back()
        // const params = {
        //   client_id: this.clientId
        // }
        // deliveryOrderApi.ifNeedApplyLocation(params).then(res => {
        //   if (res.data.need === 0) {
        //     this.$router.go(-1)
        //   } else if (res.data.need === 1) {
        //     lazyAMapApiLoaderInstance.load().then(() => {
        //       AMap.plugin('AMap.Geolocation', res => {
        //         var geolocation = new AMap.Geolocation({
        //         // 是否使用高精度定位，默认：true
        //           enableHighAccuracy: true,
        //           // 设置定位超时时间，默认：无穷大
        //           timeout: 3500,
        //           // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
        //           buttonOffset: new AMap.Pixel(10, 20),
        //           //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        //           zoomToAccuracy: true,
        //           panToLocation: true,
        //           showButton: true,
        //           //  定位按钮的排放位置,  RB表示右下
        //           buttonPosition: 'RB'
        //         })

        //         // geolocation.getCurrentPosition()
        //         // AMap.event.addListener(geolocation, 'complete', onComplete)
        //         // AMap.event.addListener(geolocation, 'error', onError)
        //         geolocation.getCurrentPosition((status, result) => {
        //           console.log(status)
        //           if (status === 'complete') {
        //             onComplete(result)
        //           } else {
        //             onError(result)
        //           }
        //         })

        //         const onComplete = (data) => {
        //           // data是具体的定位信息 position是经纬度 formattedAddress当前地址信息
        //           const { lng, lat } = data.position
        //           const params = {
        //             latitude: lat,
        //             longitude: lng
        //           }
        //           this.saveTude = params
        //           this.beginLoad()
        //           deliveryOrderApi.getCurrentLocation(params).then(res => {
        //             this.locationMsg = res.data
        //             const { province, city, district, address } = this.locationMsg
        //             this.detailAddress = province + city + district + address
        //             this.endLoad()
        //             this.confirm(`当前位置：${this.detailAddress},请确认是否在客户店里`).then(res => {
        //               this.beginLoad()
        //               const { district_code, city_code, address } = this.locationMsg
        //               const { longitude, latitude } = this.saveTude
        //               const area_code = district_code || city_code
        //               const data = {
        //                 client_id: this.clientId,
        //                 area_code,
        //                 address,
        //                 longitude,
        //                 latitude
        //               }
        //               deliveryOrderApi.changeCustomerLocation(data).then(res => {
        //                 this.endLoad()
        //                 this.success(res.msg)
        //                 this.$router.go(-1)
        //               })
        //             }).catch(() => {
        //               this.$router.go(-1)
        //             })
        //           })
        //         }
        //         const onError = (data) => {
        //           // 定位出错
        //           Dialog.alert({
        //             title: '提示',
        //             message: '定位授权失败',
        //             confirmButtonColor: '#1989fa'
        //           }).then(() => {
        //             // on close
        //             this.$router.go(-1)
        //           })
        //         }
        //       })
        //     })
        // }
        // })
      })
    },
    handleUnpaid() {
      let unpaidOrders
      if (!this.unpaidInputMode) {
        unpaidOrders = this.unpaidOrderIds.map(unpaidOrderId => {
          return {
            id: unpaidOrderId
          }
        })
      } else {
        unpaidOrders = []
        this.orders.forEach(order => {
          if (order.unpaid_amount > 0) {
            unpaidOrders.push({
              id: order.id,
              amount: order.unpaid_amount
            })
          }
        })
      }

      const data = {
        client_id: this.clientId,
        unpaid_orders: unpaidOrders
      }
      this.beginLoad()
      deliveryOrderApi.unpaid(data).then(res => {
        this.success(res.msg)
        this.getPayInfo()
      })
    },
    toggle(index) {
      let check = null
      if (this.showDialog) {
        check = this.$refs.checkbox[index]
      } else {
        check = this.$refs.radio[index]
      }

      check.toggle()
    },
    toReturnView() {
      // 退货
      this.$router.push({
        path: '/goods-return',
        query: {
          client_id: this.clientId,
          bill_reject_id: this.$route.query.bill_reject_id
        }
      })
    },
    cancelPay() {
      this.confirm('确定要撤销付款吗?').then(res => {
        const data = {
          payment_id: this.$route.query.payment_id,
          bill_reject_id: this.$route.query.bill_reject_id
        }
        deliveryOrderApi.cancelPayment(data).then(res => {
          this.success(res.msg)
          this.$router.go(-1)
        })
      })
    },
    handleUnpaidReturn() {
      const data = {
        client_id: this.clientId,
        payment_id: this.$route.query.payment_id,
        order_id: this.payInfo.unpaid_return_order_id,
        bill_reject_id: this.$route.query.bill_reject_id
      }
      setUnpaidReturn(data).then(res => {
        this.success(res.msg)
        this.showUnpaidReturnDialog = false
      })
    },
    getUnpaidReturnOrderNum(unpaidReturnOrderId) {
      if (!unpaidReturnOrderId || !this.orders) {
        return '请选择'
      } else {
        const order = this.orders.find(order => order.id === unpaidReturnOrderId)
        return order.num
      }
    },
    selectOperation(action) {
      this.showOperations = false
      if (action.type === 1) {
        this.toReturnView()
      } else if (action.type === 2) {
        this.showDialog = true
      }
    },
    handleOrderNext() {
      this.confirm('确定要下次配送吗？', '提示').then(() => {
        const data = {
          client_id: this.clientId
        }
        this.beginLoad()
        toOrderNext(data).then(() => {
          this.endLoad()
          this.$router.back()
        })
      })
    },
    handleShowPaymentQrcode() {
      const url = process.env.VUE_APP_PAYMENT_URL + '?order_num=' + this.payOrderNum
      qrcode.toDataURL(url, { width: 200 }, (err, data) => {
        if (err) {
          this.fail('生成付款二维码失败')
          console.log(err)
          return
        }
        this.showPaymentQrcodeDialog = true
        this.qrcodeData = data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/color';
  .delivery-order-payment-container{
    .my-van-dialog-css{
      top: 50%;
      max-height: 100vh;
      overflow: scroll;
    }
  }
  .cell-amount-value {
    color: #ee0a24;
  }
  .delivery-order-payment-content {
    padding: 1px 15px 15px 15px;
    &__payinfo {
      margin-top: 10px;
    }
  }
  .delivery-order-payment-mode {
    &__icon {
      display: flex;
      align-items: center;
      margin-right: 5px;
      font-size: 20px;
      &__wxpay {
        color:  #04BE02;
      }
      &__alipay {
        color: #027AFF;
      }
      &__cash {
        color: #FFD700;
      }
      &__trans {
        color: #FF7F50;
        font-size: 17px;
      }
    }
    &__input {
      padding: 0;
    }
  }
  .delivery-order-payment-submit-btn {
    margin-top: 10px;
  }
  .delivery-order-payment-unpaid {
    padding: 10px 0;
    &__cell {
      padding: 10px 20px;
    }
  }
  .delivery-order-payment-input-mode {
    .van-cell {
      &__title {
        flex: 3;
      }
    }
  }
  .payment-info-trans {
    margin-top: 10px;
    .van-cell__value {
      flex: 2;
    }
  }
  .return-receipt {
    color: #969799;
  }
  .uop-tips {
    font-size: 13px;
    padding: 0 20px;
    padding-bottom: 10px;
    color: $grayTextColor;
  }
  .unpaid-group {
    max-height: 50vh;
    overflow: scroll;
  }
  .order-next-btn {
    margin-top: 10px;
  }
  .payment-qrcode {
    text-align: center;
  }
  .tips {
    padding: 10px;
    color: #989898;
    background-color: #fff;
    margin-top: 10px;
  }
</style>
