var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "delivery-order-payment-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "付款信息",
      "left-text": "返回",
      "right-text": "撤销付款",
      "left-arrow": ""
    },
    on: {
      "click-right": _vm.cancelPay
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.payInfo ? _c('div', {
    staticClass: "delivery-order-payment-content"
  }, [_c('div', {
    staticClass: "tips"
  }, [_vm._v("提示：如果有修改，请重新点击`确认提交`")]), _c('van-cell-group', {
    attrs: {
      "title": "支付明细"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "订单总额",
      "value": '￥' + _vm.payInfo.amount,
      "value-class": "cell-amount-value"
    }
  }), _vm.depositAmount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减定金",
      "value": '￥' + _vm.depositAmount,
      "value-class": "cell-amount-value"
    }
  }) : _vm._e(), _vm.payInfo.red_packet_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减红包",
      "value": '￥' + _vm.payInfo.red_packet_amount,
      "value-class": "cell-amount-value"
    }
  }) : _vm._e(), _vm.payInfo.coupon_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减优惠券",
      "value": '￥' + _vm.payInfo.coupon_amount,
      "value-class": "cell-amount-value"
    }
  }) : _vm._e(), _vm.payInfo.return_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减退货",
      "value": '￥' + _vm.payInfo.return_amount,
      "center": "",
      "value-class": "cell-amount-value"
    }
  }) : _vm._e(), _vm.payInfo.unpaid_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减签单",
      "value": '￥' + _vm.payInfo.unpaid_amount,
      "center": "",
      "value-class": "cell-amount-value"
    }
  }) : _vm._e(), _vm.payInfo.decimal_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减尾数",
      "value": '￥' + _vm.payInfo.decimal_amount,
      "value-class": "cell-amount-value"
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "应收",
      "value": '￥' + _vm.payInfo.real_amount,
      "value-class": "cell-amount-value"
    }
  }), _c('van-cell', {
    attrs: {
      "title": "退货/签单",
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showOperations = true;
      }
    }
  }), _vm.payInfo.coupon_tips ? _c('van-cell', {
    attrs: {
      "title": "优惠券取消提醒：",
      "label": _vm.payInfo.coupon_tips
    }
  }) : _vm._e(), _vm.payInfo.red_packet_tips ? _c('van-cell', {
    attrs: {
      "title": "红包取消提醒：",
      "label": _vm.payInfo.red_packet_tips
    }
  }) : _vm._e()], 1), _vm.payInfo.unpaid_amount < 0 ? _c('van-cell-group', {
    attrs: {
      "title": "客户签单"
    }
  }, [_vm.payInfo.unpaid_return_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "签单退货金额",
      "value": '￥' + _vm.payInfo.unpaid_return_amount,
      "value-class": "cell-amount-value"
    }
  }) : _vm._e(), _vm.payInfo.unpaid_return_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "签单退货单据",
      "value": _vm.getUnpaidReturnOrderNum(_vm.payInfo.unpaid_return_order_id),
      "value-class": "return-receipt",
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showUnpaidReturnDialog = true;
      }
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "本次签单金额",
      "value": '￥' + _vm.payInfo.real_unpaid_amount,
      "value-class": "cell-amount-value"
    }
  })], 1) : _vm._e(), _c('van-cell-group', {
    attrs: {
      "title": "支付方式"
    }
  }, [_c('van-cell', {
    staticClass: "delivery-order-payment-input-mode",
    attrs: {
      "title": "输入模式",
      "center": ""
    }
  }, [_c('van-switch', {
    attrs: {
      "size": "20px"
    },
    model: {
      value: _vm.inputMode,
      callback: function callback($$v) {
        _vm.inputMode = $$v;
      },
      expression: "inputMode"
    }
  })], 1), _c('van-cell', {
    attrs: {
      "title": "查看收款码",
      "is-link": ""
    },
    on: {
      "click": _vm.handleShowPaymentQrcode
    }
  }), _c('van-radio-group', {
    attrs: {
      "direction": "horizontal"
    },
    model: {
      value: _vm.payType,
      callback: function callback($$v) {
        _vm.payType = $$v;
      },
      expression: "payType"
    }
  }, [_c('van-cell', {
    staticClass: "delivery-order-payment-mode",
    attrs: {
      "title": "线上支付",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.payType = '1';
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('span', {
          staticClass: "delivery-order-payment-mode__icon"
        }, [_c('van-icon', {
          staticClass: "delivery-order-payment-mode__icon__wxpay",
          attrs: {
            "class-prefix": "mhj-icon",
            "name": "weixinzhifu"
          }
        })], 1)];
      },
      proxy: true
    }, !_vm.inputMode ? {
      key: "right-icon",
      fn: function fn() {
        return [_c('van-radio', {
          attrs: {
            "name": "1"
          }
        })];
      },
      proxy: true
    } : {
      key: "default",
      fn: function fn(undefined) {
        return _c('van-field', {
          staticClass: "delivery-order-payment-mode__input",
          attrs: {
            "input-align": "right",
            "placeholder": "支付金额",
            "type": "number"
          },
          model: {
            value: _vm.wxpayAmount,
            callback: function callback($$v) {
              _vm.wxpayAmount = $$v;
            },
            expression: "wxpayAmount"
          }
        });
      }
    }], null, true)
  }), _c('van-cell', {
    staticClass: "delivery-order-payment-mode",
    attrs: {
      "title": "现金",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.payType = '3';
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('span', {
          staticClass: "delivery-order-payment-mode__icon"
        }, [_c('van-icon', {
          staticClass: "delivery-order-payment-mode__icon__cash",
          attrs: {
            "class-prefix": "mhj-icon",
            "name": "xianjin"
          }
        })], 1)];
      },
      proxy: true
    }, !_vm.inputMode ? {
      key: "right-icon",
      fn: function fn() {
        return [_c('van-radio', {
          attrs: {
            "name": "3"
          }
        })];
      },
      proxy: true
    } : {
      key: "default",
      fn: function fn(undefined) {
        return _c('van-field', {
          staticClass: "delivery-order-payment-mode__input",
          attrs: {
            "input-align": "right",
            "placeholder": "支付金额",
            "type": "number"
          },
          model: {
            value: _vm.cashAmount,
            callback: function callback($$v) {
              _vm.cashAmount = $$v;
            },
            expression: "cashAmount"
          }
        });
      }
    }], null, true)
  }), _c('van-cell', {
    staticClass: "delivery-order-payment-mode",
    attrs: {
      "title": "转账",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.payType = '4';
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('span', {
          staticClass: "delivery-order-payment-mode__icon"
        }, [_c('van-icon', {
          staticClass: "delivery-order-payment-mode__icon__trans",
          attrs: {
            "class-prefix": "mhj-icon",
            "name": "yinxingqia"
          }
        })], 1)];
      },
      proxy: true
    }, !_vm.inputMode ? {
      key: "right-icon",
      fn: function fn() {
        return [_c('van-radio', {
          attrs: {
            "name": "4"
          }
        })];
      },
      proxy: true
    } : {
      key: "default",
      fn: function fn(undefined) {
        return _c('van-field', {
          staticClass: "delivery-order-payment-mode__input",
          attrs: {
            "input-align": "right",
            "placeholder": "支付金额",
            "type": "number"
          },
          model: {
            value: _vm.transAmount,
            callback: function callback($$v) {
              _vm.transAmount = $$v;
            },
            expression: "transAmount"
          }
        });
      }
    }], null, true)
  })], 1)], 1), _c('van-button', {
    staticClass: "delivery-order-payment-submit-btn",
    attrs: {
      "block": "",
      "round": "",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("确认提交")]), _c('van-button', {
    staticClass: "order-next-btn",
    attrs: {
      "block": "",
      "round": "",
      "type": "danger"
    },
    on: {
      "click": _vm.handleOrderNext
    }
  }, [_vm._v("下次配送")])], 1) : _vm._e()]), _c('van-dialog', {
    attrs: {
      "title": "签单",
      "show-cancel-button": "",
      "confirm-button-text": "确定",
      "confirm-button-color": "#1989fa"
    },
    on: {
      "confirm": _vm.handleUnpaid
    },
    model: {
      value: _vm.showDialog,
      callback: function callback($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('div', {
    staticClass: "delivery-order-payment-unpaid"
  }, [_c('van-cell', {
    attrs: {
      "center": "",
      "border": false,
      "title": "输入模式"
    },
    scopedSlots: _vm._u([{
      key: "right-icon",
      fn: function fn() {
        return [_c('van-switch', {
          attrs: {
            "size": "20px"
          },
          model: {
            value: _vm.unpaidInputMode,
            callback: function callback($$v) {
              _vm.unpaidInputMode = $$v;
            },
            expression: "unpaidInputMode"
          }
        })];
      },
      proxy: true
    }])
  }), _c('van-checkbox-group', {
    staticClass: "unpaid-group",
    model: {
      value: _vm.unpaidOrderIds,
      callback: function callback($$v) {
        _vm.unpaidOrderIds = $$v;
      },
      expression: "unpaidOrderIds"
    }
  }, _vm._l(_vm.orders, function (order, index) {
    return _c('van-cell', {
      key: index,
      staticClass: "delivery-order-payment-unpaid__cell",
      attrs: {
        "title": order.num,
        "center": ""
      },
      on: {
        "click": function click($event) {
          _vm.unpaidInputMode ? function () {} : _vm.toggle(index);
        }
      },
      scopedSlots: _vm._u([!_vm.unpaidInputMode ? {
        key: "right-icon",
        fn: function fn() {
          return [_c('van-checkbox', {
            ref: "checkbox",
            refInFor: true,
            attrs: {
              "name": order.id
            }
          })];
        },
        proxy: true
      } : {
        key: "default",
        fn: function fn(undefined) {
          return _c('van-field', {
            staticClass: "delivery-order-payment-mode__input",
            attrs: {
              "input-align": "right",
              "placeholder": "签单金额",
              "type": "number"
            },
            model: {
              value: order.unpaid_amount,
              callback: function callback($$v) {
                _vm.$set(order, "unpaid_amount", $$v);
              },
              expression: "order.unpaid_amount"
            }
          });
        }
      }], null, true)
    });
  }), 1)], 1)]), _c('van-dialog', {
    attrs: {
      "title": "签单退货单据",
      "show-cancel-button": "",
      "confirm-button-text": "确定",
      "confirm-button-color": "#1989fa"
    },
    on: {
      "confirm": _vm.handleUnpaidReturn
    },
    model: {
      value: _vm.showUnpaidReturnDialog,
      callback: function callback($$v) {
        _vm.showUnpaidReturnDialog = $$v;
      },
      expression: "showUnpaidReturnDialog"
    }
  }, [_vm.payInfo ? _c('div', {
    staticClass: "delivery-order-payment-unpaid"
  }, [_c('div', {
    staticClass: "uop-tips"
  }, [_vm._v("提示：请选择写有退货的单据")]), _c('van-radio-group', {
    model: {
      value: _vm.payInfo.unpaid_return_order_id,
      callback: function callback($$v) {
        _vm.$set(_vm.payInfo, "unpaid_return_order_id", $$v);
      },
      expression: "payInfo.unpaid_return_order_id"
    }
  }, _vm._l(_vm.orders, function (order, index) {
    return _c('van-cell', {
      key: index,
      staticClass: "delivery-order-payment-unpaid__cell",
      attrs: {
        "title": order.num,
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toggle(index);
        }
      },
      scopedSlots: _vm._u([{
        key: "right-icon",
        fn: function fn() {
          return [_c('van-radio', {
            ref: "radio",
            refInFor: true,
            attrs: {
              "name": order.id
            }
          })];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1) : _vm._e()]), _c('van-dialog', {
    staticClass: "my-van-dialog-css",
    attrs: {
      "title": "收款信息",
      "show-cancel-button": "",
      "confirm-button-text": "确定",
      "confirm-button-color": "#1989fa"
    },
    on: {
      "confirm": _vm.handlePayment
    },
    model: {
      value: _vm.showPaymentDialog,
      callback: function callback($$v) {
        _vm.showPaymentDialog = $$v;
      },
      expression: "showPaymentDialog"
    }
  }, [_vm.recvInfo ? _c('div', {
    staticClass: "payment-info"
  }, [_c('div', {
    staticClass: "payment-info-trans"
  }, [_c('van-cell', {
    attrs: {
      "title": "开户行",
      "value": _vm.recvInfo.bank_name
    }
  }), _c('van-cell', {
    attrs: {
      "title": "开户名",
      "value": _vm.recvInfo.bank_account
    }
  }), _c('van-cell', {
    attrs: {
      "title": "银行卡号",
      "value": _vm.recvInfo.bank_card_num
    }
  })], 1)]) : _vm._e()]), _c('my-dialog', {
    attrs: {
      "title": "收款码",
      "show-cancel-button": false
    },
    model: {
      value: _vm.showPaymentQrcodeDialog,
      callback: function callback($$v) {
        _vm.showPaymentQrcodeDialog = $$v;
      },
      expression: "showPaymentQrcodeDialog"
    }
  }, [_c('div', {
    staticClass: "payment-qrcode"
  }, [_c('van-image', {
    attrs: {
      "src": _vm.qrcodeData
    }
  })], 1)]), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.selectOperation
    },
    model: {
      value: _vm.showOperations,
      callback: function callback($$v) {
        _vm.showOperations = $$v;
      },
      expression: "showOperations"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }